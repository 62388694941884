import ApplicationType from 'constants/ApplicationType';

import { Box, Grid, Icon, Paper, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Breadcrumb from 'oldComponents/Breadcrumb';
import ErrorBudget from 'oldComponents/ErrorBudget';
import { FrequenciesMetrics } from 'oldComponents/FrequenciesMetrics';
import { LeadTimeIntegrationApplicationCard } from 'oldComponents/LeadTimeIntegrationApplicationCard';
import { Metric } from 'oldComponents/Metric';
import { MetricsItem } from 'oldComponents/MetricsItem';
import { Link } from 'react-router-dom';

import formatSecondsInWords from 'helpers/formatSecondsInWords';
import { useMtbf, useMtta, useMttrecovery } from 'hooks/queriesGraphQL/useMtts';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { useFeaturePlan } from 'hooks/useFeaturePlan';

import ApplicationMetricsButton from '../components/ApplicationMetricsButton';
import { MTBFTitle, MTTATitle, MTTRecoveryTitle } from '../components/Titles';
import UptimeTable from '../components/UptimeTable';

import ApplicationCardInfo from './ApplicationCardInfo';
import LatencyTabs from './Metrics/TabLatencies';

const styles = theme => ({
  root: {
    marginBottom: 10
  },
  infoHeader: {
    marginBottom: theme.spacing(1),
    flexGrow: 1
  },
  metricsCards: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 'auto'
  },
  badge: {
    display: 'block',
    borderRadius: 20,
    backgroundColor: 'green',
    border: '1px solid #000000',
    width: 16,
    height: 16,
    marginRight: theme.spacing()
  },
  informations: {
    display: 'flex',
    alignItems: 'center'
  },
  gutterTop: {
    marginTop: theme.spacing()
  },
  uptime: {
    padding: theme.spacing(3, 2.25)
  },
  errorBudget: {
    padding: theme.spacing(3, 2.25)
  },
  descriptionIcon: {
    fontSize: 18,
    marginLeft: 2
  }
});

const TopSection = ({
  resourceLastCheck,
  classes,
  application,
  applicationId,
  applicationName,
  applicationUid,
  monitoringActive,
  productId,
  productName,
  addonHttpFlow,
  onUpdateAddon,
  maintenance,
  submitDeleteMaintenance,
  latenciesGraphData,
  applicationStatusUniqueResource,
  reexecuteQueryUniqueResourceStatus,
  monitoringStatus,
  addons
}) => {
  const dataMtta = useMtta({ serviceId: application?.sterasId });
  const dataMtbf = useMtbf({ serviceId: application?.sterasId });
  const dataMttrecovery = useMttrecovery({ serviceId: application?.sterasId });

  const useResourceFlag = useFeatureFlagUnleash('useResource', { queryString: true });
  const shouldUseResource = Boolean(useResourceFlag && application?.useResource);

  const selectAgentType = application => {
    const applicationTypeList = Object.values(ApplicationType);
    const foundType = applicationTypeList.find(
      type => type.type === application.applicationsSettings?.type
    );
    return foundType ? foundType.agentType : ' --';
  };

  const { last_check } = application.status[0] || {};

  const canUseMetrics = useFeaturePlan('Metrics');
  const canUseSREMetrics = useFeaturePlan('Metrics.SRE');
  const canUseUptimeMetrics = useFeaturePlan('Metrics.Uptime');
  const canFetchDataFromTheLast30Days = useFeaturePlan('DataRetention.30Days');

  const mtbfMetric = canFetchDataFromTheLast30Days
    ? dataMtbf?.period?.thirtyDays
    : dataMtbf?.period?.oneDay;

  const mttaMetric = canFetchDataFromTheLast30Days
    ? dataMtta?.period?.thirtyDays
    : dataMtta?.period?.oneDay;

  const mttrMetric = canFetchDataFromTheLast30Days
    ? dataMttrecovery?.period?.thirtyDays
    : dataMttrecovery?.period?.oneDay;

  const metricsHelperText = canFetchDataFromTheLast30Days ? 'Last 30 days' : 'Last 24 hours';

  const mtbf = {
    name: <MTBFTitle />,
    value: formatSecondsInWords(mtbfMetric || 0),
    success: false
  };
  const mtta = {
    name: <MTTATitle />,
    value: formatSecondsInWords(mttaMetric || 0),
    success: true
  };
  const mttRecovery = {
    name: <MTTRecoveryTitle />,
    value: formatSecondsInWords(mttrMetric || 0),
    success: true
  };

  const previousDayDate = new Date();
  previousDayDate.setDate(previousDayDate.getDate() - 1);

  return (
    <Grid
      container
      justify="space-between"
      alignItems="flex-start"
      application-data={application.name}
      className={classes.root}>
      <Breadcrumb
        style={{
          margin: '0 0 16px 0'
        }}
        items={[
          { link: `/products`, label: 'Products' },
          { link: `/products/${productId}`, label: productName },
          { label: applicationName }
        ]}
      />

      <Grid item xs={12} style={{ marginBottom: '1rem' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">{application.name}</Typography>

          <ApplicationMetricsButton sterasId={application?.sterasId} name={application.name} />
        </Box>
      </Grid>

      <ApplicationCardInfo
        application={application}
        applicationStatusUniqueResource={applicationStatusUniqueResource}
        monitoringActive={monitoringActive}
        productId={productId}
        addonHttpFlow={addonHttpFlow}
        onUpdateAddon={onUpdateAddon}
        applicationId={applicationId}
        selectAgentType={selectAgentType}
        lastCheck={shouldUseResource ? resourceLastCheck : last_check}
        previousDayDate={previousDayDate}
        maintenance={maintenance}
        submitDeleteMaintenance={submitDeleteMaintenance}
        reexecuteQueryUniqueResourceStatus={reexecuteQueryUniqueResourceStatus}
        monitoringStatus={monitoringStatus}
        addons={addons}
      />

      <Grid
        sm={8}
        xs={12}
        item
        container
        spacing={2}
        className={classes.metricsCards}
        justify="flex-start">
        <MetricsItem>
          <Metric entity={mtbf} helperText={metricsHelperText} featurePlan="Metrics.SRE" />
        </MetricsItem>
        <MetricsItem>
          <Metric entity={mtta} helperText={metricsHelperText} featurePlan="Metrics.SRE" />
        </MetricsItem>
        <MetricsItem>
          <Metric entity={mttRecovery} helperText={metricsHelperText} featurePlan="Metrics.DORA" />
        </MetricsItem>

        <FrequenciesMetrics applicationId={Number(applicationId)} />

        <MetricsItem>
          <LeadTimeIntegrationApplicationCard applicationUid={applicationUid} />
        </MetricsItem>

        <Grid item xs={12}>
          <Paper elevation={1} className={classes.uptime}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">Uptime</Typography>
            </Box>
            {canUseUptimeMetrics && monitoringActive && (
              <UptimeTable
                sterasId={application?.sterasId}
                resourceType="Application"
                monitoringActive={monitoringActive}
                striped
              />
            )}
            {!canUseUptimeMetrics && (
              <Box mt={2}>
                <Link to="/plans">
                  <Typography variant="h6" color="primary">
                    Upgrade your plan
                  </Typography>
                </Link>
              </Box>
            )}
          </Paper>
        </Grid>
        <Grid item xs={5}>
          <Paper elevation={1} className={classes.errorBudget}>
            <Box display="flex" alignItems="center">
              <Typography variant="h6"> Error Budget </Typography>
              <Tooltip title={'Metric used to display your error budget burned.'}>
                <Icon color="primary" className={classes.descriptionIcon}>
                  help
                </Icon>
              </Tooltip>
            </Box>
            {canUseSREMetrics && monitoringActive && (
              <ErrorBudget
                sterasId={application.sterasId}
                interval={application?.applicationsSettings?.check_interval}
                isMonitoringActive={monitoringActive}
              />
            )}

            {!canUseMetrics && (
              <Box mt={2}>
                <Link to="/plans">
                  <Typography variant="h5" color="primary">
                    Upgrade your plan
                  </Typography>
                </Link>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>

      {canUseMetrics && monitoringActive && (
        <Grid item xs={12}>
          <LatencyTabs service={application?.sterasId} application={application?.id} />
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(styles)(TopSection);
