import { useCallback, useRef, useState } from 'react';

import {
  AppBar,
  Avatar,
  Box,
  Container,
  Divider,
  IconButton,
  Toolbar as ToolbarMui,
  Typography,
  makeStyles
} from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import ImportantUpdates from 'oldComponents/ImportantUpdates';
import PlansMessage from 'oldComponents/PlansMessage';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import usePlans from 'hooks/queriesGraphQL/usePlans';
import useUser from 'hooks/queriesRest/useUser';
import { useLoggedUserRole } from 'hooks/useLoggedUserRole';
import { TechnologyNetworkShare } from 'icons/TechnologyNetworkShare';
import GlobalMessage from 'layouts/MainV1/components/Topbar/GlobalMessage';
import { GlobalMessages } from 'layouts/V4/components/Toolbar/types';
import actions from 'redux/actions';
import { directusV2 } from 'services/directus';

import { CriticalEvents } from './CriticalEvents';
import { ToolbarMenu } from './Menu';
import { Navbar } from './Navbar';

const useStyles = makeStyles(theme => ({
  appBar: {
    padding: theme.spacing(1, 8.75),
    background: `linear-gradient(90deg, ${theme.palette.secondary.main}, ${theme.palette.primary.light})`,
    height: theme.spacing(10)
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button': {
      padding: 0
    },
    '& hr': {
      backgroundColor: theme.palette.grey[50]
    }
  },
  avatar: {
    width: 32,
    height: 32,
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.primary.main
  }
}));

export function Toolbar() {
  const classes = useStyles();
  const profileMenuButtonRef = useRef<HTMLButtonElement>(null);
  const [profileMenuRef, setProfileMenuRef] = useState<HTMLElement | null>(null);

  const helpMenuButtonRef = useRef<HTMLButtonElement>(null);
  const [helpMenuRef, setHelpMenuRef] = useState<HTMLElement | null>(null);

  const { data: user } = useUser();

  const { orgStatus, showMessage } = usePlans();

  const [result] = useLoggedUserRole();

  const role = result.data?.role.name;

  const { data: menuHelpData } = useQuery('menu_help', async () => {
    return (await directusV2.items('menu_help').readByQuery({})).data;
  });

  const menuHelp = menuHelpData?.map(menuItem => ({
    ...menuItem,
    href: menuItem.link,
    planFeature: ''
  }));

  const { data: menuItemsData } = useQuery('menu_toolbar', async () => {
    return (await directusV2.items('menu_toolbar').readByQuery({})).data;
  });

  const menuItems = menuItemsData?.map(menuItem => ({
    ...menuItem,
    href: menuItem.link,
    planFeature: ''
  }));

  const isLogged = Boolean(user?.logged?.org);
  const isOrgBlocked = user?.logged?.org?.signatureStatus === 'blocked';
  const orgName = user?.logged?.org?.name;

  const menuItemsFiltered = () => {
    if (!isLogged) {
      const options = ['/logout'];

      return menuItems?.filter(item => options.includes(item?.link));
    }

    if (isOrgBlocked) {
      const options = ['/logout', '/plans', '/select-org'];

      return menuItems?.filter(item => options.includes(item?.link));
    }

    return role ? menuItems?.filter(item => item?.allowed_roles?.includes(role)) : [];
  };

  const dispatch = useDispatch();

  const { globalErrors, globalSuccess, globalWarning } = useSelector(
    (state: GlobalMessages) => state
  );

  const reset = useCallback(() => dispatch({ type: actions.CLEAR_ERROR }), [dispatch]);

  return (
    <>
      <AppBar elevation={0} position="sticky">
        {showMessage && <PlansMessage message={orgStatus?.message} />}

        <Box className={classes.appBar}>
          <Container maxWidth="xl" disableGutters>
            <ToolbarMui disableGutters className={classes.toolbar}>
              <Link to="/">
                <img
                  alt="Elven Platform"
                  src="/images/logos/elven-platform-horizontal-full-white.svg"
                />
              </Link>

              <Box display="flex" gridGap="1rem" alignItems="center">
                {orgName && (
                  <>
                    <Box>
                      <Typography variant="caption">
                        <strong>Organization</strong>
                      </Typography>
                      <Typography variant="body1">
                        <strong>{user.logged.org.name}</strong>
                      </Typography>
                    </Box>
                    <Divider orientation="vertical" flexItem />
                  </>
                )}

                <IconButton
                  id="help-menu"
                  title="Open help menu"
                  ref={helpMenuButtonRef}
                  onClick={() => {
                    if (helpMenuRef) {
                      setHelpMenuRef(null);
                      return;
                    }

                    setHelpMenuRef(helpMenuButtonRef?.current);
                  }}>
                  <Avatar className={classes.avatar}>
                    <HelpOutline />
                  </Avatar>
                </IconButton>
                <ToolbarMenu
                  anchorEl={helpMenuRef}
                  menuItems={menuHelp ?? []}
                  placement="bottom-end"
                />

                <Divider orientation="vertical" flexItem />

                {!isOrgBlocked && isLogged && (
                  <>
                    <CriticalEvents>
                      <Avatar className={classes.avatar}>
                        <TechnologyNetworkShare />
                      </Avatar>
                    </CriticalEvents>
                    <Divider orientation="vertical" flexItem />
                  </>
                )}

                <IconButton
                  id="profile-menu"
                  title="Open menu"
                  ref={profileMenuButtonRef}
                  onClick={() => {
                    if (profileMenuRef) {
                      setProfileMenuRef(null);
                      return;
                    }

                    setProfileMenuRef(profileMenuButtonRef?.current);
                  }}>
                  <Avatar
                    className={classes.avatar}
                    alt={user?.displayName}
                    src={user?.photoURL ?? 'without_photo'}
                  />
                </IconButton>

                <ToolbarMenu
                  anchorEl={profileMenuRef}
                  menuItems={menuItemsFiltered() || []}
                  placement="bottom-end"
                />
              </Box>
            </ToolbarMui>
          </Container>
        </Box>
        {isLogged && !isOrgBlocked && <Navbar />}
        <ImportantUpdates />
        <GlobalMessage
          globalErrors={globalErrors}
          globalSuccess={globalSuccess}
          globalWarning={globalWarning}
          reset={reset}
        />
      </AppBar>
    </>
  );
}
