import { MenuItem, makeStyles } from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import { MenuSidebar } from 'types/directus';

import { LockChip } from 'components/LockChip';
import { Menu, MenuProps } from 'components/Menu';
import { composeNavigationLink } from 'helpers/composeNavigationLink';
import { isExternalUrl } from 'helpers/isExternalUrl';
import { useFeaturePlan } from 'hooks/useFeaturePlan';

type ToolbarMenuProps = {
  menuItems: Pick<MenuSidebar, 'id' | 'href' | 'title' | 'planFeature'>[];
} & Pick<MenuProps, 'anchorEl' | 'anchorElId' | 'placement'>;

const useStyles = makeStyles(theme => ({
  popper: {
    minWidth: 424
  },
  menuItem: {
    '&.Mui-disabled': {
      opacity: 'initial'
    }
  }
}));

function ToolbarMenuItem({ menuItem }: { menuItem: ToolbarMenuProps['menuItems'][number] }) {
  const classes = useStyles();

  const isExternal = isExternalUrl(menuItem.href ?? '');

  const isFeaturePlanEnabled = useFeaturePlan(menuItem.planFeature, { placeholder: true });

  const isPageAccessibleByPlan = menuItem.planFeature ? isFeaturePlanEnabled : true;

  return (
    <MenuItem
      key={menuItem.id}
      data-active={menuItem.href === window.location.pathname}
      className={classes.menuItem}
      {...{
        target: isExternalUrl(menuItem.href ?? '') ? '_blank' : '_self',
        component: NavLink,
        to: composeNavigationLink(menuItem.href ?? ''),
        rel: 'noopener noreferrer'
      }}
      disabled={!isPageAccessibleByPlan}>
      {menuItem.title}
      {isExternal && <Launch fontSize="small" htmlColor="inherit" />}
      {!isPageAccessibleByPlan && <LockChip />}
    </MenuItem>
  );
}

export function ToolbarMenu({
  anchorElId = '',
  menuItems,
  anchorEl,
  placement = 'bottom-start'
}: ToolbarMenuProps) {
  const classes = useStyles();

  return (
    <Menu
      anchorElId={anchorElId}
      anchorEl={anchorEl}
      popperProps={{
        className: classes.popper,
        placement
      }}>
      {menuItems?.map(item => (
        <ToolbarMenuItem key={item.id} menuItem={item} />
      ))}
    </Menu>
  );
}
