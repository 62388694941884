import { Typography, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';

import formatSecondsInWords from 'helpers/formatSecondsInWords';
import { useMtbf, useMtta, useMttrecovery } from 'hooks/queriesGraphQL/useMttsV2';
import { useFeaturePlan } from 'hooks/useFeaturePlan';

import { useStyles } from '../../styles';

export function MttsCard({
  name,
  originUid,
  entity,
  monitoringIsActive
}: {
  name: string;
  originUid: string;
  entity: string;
  monitoringIsActive: boolean;
}) {
  const classes = useStyles();

  const canUseMetrics = useFeaturePlan('Metrics.SRE');

  const dataMtbf = useMtbf({ originUid, entity, disabled: !monitoringIsActive });

  const dataMtta = useMtta({ originUid, entity, disabled: !monitoringIsActive });

  const dataMttrecovery = useMttrecovery({
    originUid,
    entity,
    disabled: !monitoringIsActive
  });

  const metric = () => {
    if (name === 'MTBF') {
      return dataMtbf;
    }

    if (name === 'MTTA') {
      return dataMtta;
    }

    if (name === 'MTTRecovery') {
      return dataMttrecovery;
    }
  };

  const cardData = () => {
    if (!canUseMetrics) {
      return (
        <Link to="/plans">
          <Typography variant="h5" color="primary" className={classes.upgradePlanText}>
            Upgrade your plan
          </Typography>
        </Link>
      );
    }

    if (monitoringIsActive) {
      return (
        <>
          <Typography variant="h5" className={classes.paperTextMiddle}>
            {formatSecondsInWords(metric()?.period?.thirtyDays || 0)}
          </Typography>
          <Typography variant="body2" className={classes.paperTextBottom}>
            Last 30 days
          </Typography>
        </>
      );
    }

    return (
      <Typography variant="h5" className={classes.paperTextMiddle}>
        Monitoring inactive
      </Typography>
    );
  };

  return (
    <Paper elevation={1} className={classes.paperTop}>
      <Typography variant="subtitle2" className={classes.paperTitle}>
        {name}
      </Typography>
      {cardData()}
    </Paper>
  );
}
