import MainLayoutV5 from 'layouts/v5/Main';
import { AuthorizedRoute } from 'routes/components/AuthorizedRoute';
import TeamsListView from 'views/Teams';
import TeamsNewFormView from 'views/Teams/New';
import TeamsView from 'views/Teams/View';
import stepGroupsList from 'views/Teams/View/CallRotation/StepsGroups/List/StepGroupList';
import TeamsEditMember from 'views/Teams/View/Members/Edit';
import Slack from 'views/Teams/View/SlackConfirmation';

export const teamsRoutesWithLayoutV2 = [
  <AuthorizedRoute
    component={stepGroupsList}
    exact
    layout={MainLayoutV5}
    key="/teams/stepGroups"
    path="/teams/:id/stepGroups"
    planFeature="CallRotation"
  />,
  <AuthorizedRoute
    component={TeamsListView}
    exact
    layout={MainLayoutV5}
    key="/teams"
    path="/teams"
  />,
  <AuthorizedRoute
    component={TeamsNewFormView}
    exact
    layout={MainLayoutV5}
    key="/teams/new"
    path="/teams/new"
    featureTarget="TeamController-post-/teams"
  />,
  <AuthorizedRoute
    component={TeamsView}
    exact
    layout={MainLayoutV5}
    key="/teams/:id"
    path="/teams/:id"
  />,
  <AuthorizedRoute
    component={Slack}
    exact
    layout={MainLayoutV5}
    key="/teams/:id/slack-confirmation"
    path="/teams/:id/slack-confirmation"
    planFeature="NotifyChannels.Slack"
    featureTarget="CommunicationIntegrationController-post-/save_slack_token"
  />,
  <AuthorizedRoute
    component={TeamsEditMember}
    exact
    layout={MainLayoutV5}
    key="/teams/:teamId/members/:memberId/edit"
    path="/teams/:teamId/members/:memberId/edit"
    featureTarget="MemberController-put-/updateMember"
  />
];
