import { ElementType } from 'react';

import { Button as MuiButton, ButtonProps } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import useStyles from './styles';

interface CustomButtonProps extends ButtonProps {
  component?: ElementType;
  to?: string;
}

export const Button = ({
  size = 'large',
  variant = 'contained',
  color = 'primary',
  startIcon = <Add />,
  disabled = false,
  title,
  children,
  ...props
}: CustomButtonProps) => {
  const classes = useStyles();

  return (
    <MuiButton
      size={size}
      variant={variant}
      color={color}
      startIcon={startIcon}
      disabled={disabled}
      title={title}
      className={classes.root}
      {...props}>
      {children}
    </MuiButton>
  );
};
