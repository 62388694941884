import { useMemo } from 'react';

import { Card, CardHeader, CardContent, CardActions, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Breadcrumb from 'oldComponents/Breadcrumb';
import { useHistory } from 'react-router';
import { useMutation as useMutationGraphql } from 'urql';
import { useQuery } from 'urql';

import { ButtonSimple } from 'componentsV4/Button';

import Form from './Form';
import useFormState from './useFormState';
import useGetInitialData from './useGetInitialData';
import useGetRoles from './useGetRoles';

const styles = () => ({
  list: {
    marginBottom: 15
  },
  title: {}
});

const updateMember = `#graphql
  mutation(
    $id: Float!
    $teamId: Float!
    $roleId: Float!
    $isActive: Boolean!
  ){
    updateMember(
      id: $id
      teamId: $teamId
      roleId: $roleId
      isActive: $isActive
    )
  }
`;

const MembersForm = ({
  classes,
  match: {
    params: { teamId, memberId }
  }
}) => {
  const [formState, dispatch] = useFormState({ teamId, memberId });

  const [, updateMemberMutation] = useMutationGraphql(updateMember);

  const getMemberV2 = `#graphql
    query(
      $memberId: Int!
    ) {
      getMemberV2(memberId: $memberId) {
        roleId
        isActive
        user{
          displayName
          profile {
            displayName
            photoURL
            phone
            verifiedPhone
          }
          email
        }
      }
    }
  `;

  const [result] = useQuery({
    query: getMemberV2,
    variables: { memberId: Number(memberId), teamId: Number(teamId) }
  });

  const memberResult = () => {
    if (result.data && result.data.getMemberV2) {
      return result.data.getMemberV2;
    }

    return undefined;
  };

  const member = memberResult();

  useMemo(() => {
    if (member) {
      dispatch({
        type: 'initialFetch',
        payload: {
          member: `${member.user.displayName} - ${member.user.email}`,
          roleId: member.roleId,
          isActive: member.isActive
        }
      });
    }
  }, [member, dispatch]);

  useGetInitialData({
    teamId,
    memberId,
    onSuccess: ({ data }) => {
      dispatch({
        type: 'initialFetch',
        payload: {
          member: `${data.user.displayName} - ${data.user.email}`,
          roleId: data.roleId,
          isActive: data.isActive
        }
      });
    },
    enabled: false
  });

  const { roles } = useGetRoles();

  const onChange = ({ name, value }) => {
    dispatch({ type: 'change', payload: { [name]: value } });
  };

  const history = useHistory();

  const onUpdateMember = async event => {
    event.preventDefault();

    const { roleId, isActive } = formState.values;

    await updateMemberMutation({
      id: Number(memberId),
      teamId: Number(teamId),
      roleId: Number(roleId),
      isActive
    }).then(() => {
      history.push(`/teams/${teamId}`);
    });
  };

  const title = (
    <span className={classes.title}>
      Editing <b>{formState.values.member}</b>{' '}
      {/* {team.name ? (
        <>
          on the <b>{team.name}</b> team
        </>
      ) : (
        ''
      )} */}
    </span>
  );

  return (
    <>
      <Breadcrumb
        items={[
          { link: `/teams`, label: 'Teams' },
          // { link: `/teams/${team.id}`, label: team.name },
          { label: 'Edit member' }
        ]}
      />
      <Card>
        <form name="CrudForm">
          <CardHeader title={title} />
          <Divider />
          <CardContent>
            <Form formState={formState} roles={roles} onChange={onChange} />
          </CardContent>
          <Divider />
          <CardActions>
            <ButtonSimple
              title="Save"
              color="primary"
              disabled={!formState.isValid}
              onClick={onUpdateMember}
              type="submit"
              variant="contained"
              text="Save"
            />
          </CardActions>
        </form>
      </Card>
    </>
  );
};

export default withStyles(styles)(MembersForm);
