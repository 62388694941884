import { OriginEnum } from './Origin';
import { SeverityStatusEnum } from './Severity';
import { StatusEnum } from './Status';

export type AlertCenterType = {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  uid: string;
  orgUid: string;
  ruleId: number | null;
  title: string;
  cause: string;
  origin: OriginEnum;
  severity: SeverityStatusEnum;
  status: StatusEnum;
  assigned_id: number;
  external_aggregate_key: string;
  integration_uid: string;
};

export enum RuleActionType {
  Hits = 'hits',
  Failures = 'failures',
  Timeout = 'timeout'
}

export enum RuleAction {
  Close = 'close',
  Open = 'open'
}

export enum RuleType {
  Incident = 'incident',
  Alert = 'alert'
}

export enum RuleOriginEntityType {
  Resource = 'resource',
  Multiflow = 'multiflow',
  Integration = 'integration',
  Service = 'service'
}

export type Rule = {
  uid: string;
  severity: string;
  type: string;
  actions: {
    id: number;
    action: RuleAction;
    type: RuleActionType;
    operation: string;
    occurrence: number;
    interval: number;
  }[];
  teams: {
    teamId: number;
  }[];
};

export type UpdateIncidentRulePayload = {
  ruleUid: string;
  severity: string;
  actions: {
    id: number;
    action: string;
    type: string;
    operation: string;
    occurrence: number;
    interval: number;
  }[];
  teamIds: number[];
};

export type UpdateNewIncidentRulePayload = {
  uid: string;
  severity: string;
  type: string;
  title: string;
  description: string;
  actions: {
    action: string;
    type: string;
    operation: string;
    occurrence: number;
    interval: number;
  }[];
  teamIds: number[];
  origins: {
    id?: number;
    entity: string;
    entityUid: string;
  }[];
};

export type CreateRulePayload = {
  title: string;
  description: string;
  severity: string;
  type: string;
  actions: {
    occurrence: number;
    interval: number;
  }[];
  teams: {
    teamId: number;
  }[];
  origins: {
    entityUid: string;
  }[];
};

export type UpdateRulePayload = {
  uid: string;
  title: string;
  description: string;
  severity: string;
  type: string;
  actions: {
    id: number;
    action: string;
    type: string;
    operation: string;
    occurrence: number;
    interval: number;
  }[];
  origins: {
    id?: number;
    entity: string;
    entityUid: string;
  }[];
  teamIds: number[];
};
