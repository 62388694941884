import { useEffect, useState } from 'react';

import { apm } from '@elastic/apm-rum';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Divider, Typography } from '@material-ui/core';
import { CreateIncidentRuleMutation } from 'graphqlQueries/createIncidentRule';
import { CreateMultiFlowMutation } from 'graphqlQueries/createMultiFlow';
import { DeleteRuleMutation } from 'graphqlQueries/deleteRule';
import { UpdateIncidentRuleMutation } from 'graphqlQueries/updateIncidentRule';
import { UpdateMultiFlowMutation } from 'graphqlQueries/updateMultiFlow';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Rule, RuleOriginEntityType } from 'types/external/AlertCenter';
import { CreateMultiFlowOutput } from 'types/external/MultiFlow';
import { useMutation } from 'urql';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { usePermission } from 'hooks/usePermission';
import useRule from 'hooks/useRule';
import actions from 'redux/actions';
import { updateMultiFlowAdapter } from 'views/ServicesHub/adapters/edit/multiflow';
import { multiFlowAdapter, MultiFlowFormData } from 'views/ServicesHub/adapters/new/multiflow';
import {
  incidentsRulesAdapter,
  updateIncidentRuleAdapter
} from 'views/ServicesHub/adapters/new/rules/incident';
import {
  incidentsNewRulesAdapter,
  updateNewIncidentRuleAdapter
} from 'views/ServicesHub/adapters/new/rules/ruleIncident';
import { LayoutType } from 'views/ServicesHub/forms';

import KeysForm from '../../../../views/Products/View/Availability/components/Resources/Form/MultiHttpForm/KeysComplete/KeysForm';
import { IncidentForm, IncidentFormData } from '../../forms/monitoring/commom/Incident';
import { BaseForm } from '../../forms/multiflow/common/Base';

import Step from './components/Step';
import { defaultValues, stepDefaultValue } from './defaultValues';
import { schema } from './schema';
import { useStyles } from './styles';
import { useStartValues } from './useStartValues';

export function MultiFlowLayout({ Form, isEdit }: LayoutType) {
  const classes = useStyles();

  const { startValues, fetching: fetchingStartValues } = useStartValues();

  const routeParams = useParams<{ uid: string }>();

  const form = useForm<any>({
    resolver: zodResolver(schema),
    mode: 'all',
    defaultValues
  });

  const {
    reset,
    control,
    trigger,
    watch,
    formState: { isDirty, isValid }
  } = form;

  const { fields, append: addMoreStep, remove: removeStep } = useFieldArray({
    keyName: 'stepsId',
    control,
    name: 'steps'
  });

  const history = useHistory();

  const dispatch = useDispatch();

  const useRuleManager = useFeatureFlagUnleash('useRuleManagerAPI', {
    queryString: true
  });

  const [{ fetching: creatingMultiFlow }, createMultiFlow] = useMutation<{
    createMultiFlow: CreateMultiFlowOutput;
  }>(CreateMultiFlowMutation);
  const [{ fetching: updatingMultiFlow }, updateMultiFlow] = useMutation(UpdateMultiFlowMutation);

  const [{ fetching: creatingIncidentRule }, createIncidentRule] = useMutation(
    CreateIncidentRuleMutation
  );
  const [{ fetching: updatingIncidentRule }, updateIncidentRule] = useMutation(
    UpdateIncidentRuleMutation
  );
  const [{ fetching: deletingRule }, deleteRule] = useMutation(DeleteRuleMutation);

  const { createRule, deleteRule: deleteRuleEvent, updateRule, isFetchingRule } = useRule();

  const fetching =
    creatingMultiFlow ||
    creatingIncidentRule ||
    fetchingStartValues ||
    deletingRule ||
    updatingIncidentRule ||
    updatingMultiFlow ||
    isFetchingRule;

  const [saving, setSaving] = useState(false);
  const hasIncidentRule = Boolean(startValues?.rules?.incident?.enable);

  const handleSubmit = async (data: MultiFlowFormData & { rules: IncidentFormData }) => {
    setSaving(true);
    const { rules, ...multiFlowData } = data;

    const multiFlow = multiFlowAdapter(multiFlowData);

    if (isEdit && startValues) {
      const multiFlow = updateMultiFlowAdapter(multiFlowData);

      const multiflowResponse = await updateMultiFlow({
        data: multiFlow
      });

      if (multiflowResponse.error) {
        setSaving(false);
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Error on update multiflow' }
        });

        // eslint-disable-next-line no-console
        apm.captureError(multiflowResponse.error);
        return;
      }

      if (!hasIncidentRule && rules.incident.enable) {
        const incidentRules = useRuleManager
          ? incidentsNewRulesAdapter({
              incident: { ...rules.incident },
              entityUid: routeParams.uid,
              entity: RuleOriginEntityType.Multiflow
            })
          : incidentsRulesAdapter({
              incident: rules.incident,
              entityUid: routeParams.uid,
              entity: RuleOriginEntityType.Multiflow
            });

        const incidentRulesResponse = useRuleManager
          ? await createRule({
              rule: incidentRules
            })
          : await createIncidentRule({
              rule: incidentRules
            });

        if (incidentRulesResponse.error) {
          setSaving(false);
          dispatch({
            type: actions.ENTITY_ERROR,
            payload: { message: 'Error on create incident rules' }
          });

          // eslint-disable-next-line no-console
          apm.captureError(incidentRulesResponse.error);
          return;
        }
      }

      if (hasIncidentRule && !rules.incident.enable) {
        const incidentRulesResponse = useRuleManager
          ? await deleteRuleEvent({
              ruleUid: startValues.rules.incident.uid
            })
          : await deleteRule({
              ruleUid: startValues.rules.incident.uid
            });

        if (incidentRulesResponse.error) {
          setSaving(false);
          dispatch({
            type: actions.ENTITY_ERROR,
            payload: { message: 'Error on delete incident rule' }
          });

          // eslint-disable-next-line no-console
          apm.captureError(incidentRulesResponse.error);
          return;
        }
      }

      if (hasIncidentRule && rules.incident.enable) {
        const updateIncidentRuleData = useRuleManager
          ? updateNewIncidentRuleAdapter({
              incidentRule: startValues.rules.incidentRule as Rule,
              ruleFormData: {
                ...rules.incident,
                entity: RuleOriginEntityType.Multiflow,
                entityUid: routeParams.uid
              }
            })
          : updateIncidentRuleAdapter({
              incidentRule: startValues.rules.incidentRule as Rule,
              incident: rules.incident
            });

        const updateIncidentRuleResponse = useRuleManager
          ? await updateRule({
              data: updateIncidentRuleData
            })
          : await updateIncidentRule({
              data: updateIncidentRuleData
            });

        if (updateIncidentRuleResponse.error) {
          setSaving(false);
          dispatch({
            type: actions.ENTITY_ERROR,
            payload: { message: 'Error on update the incident rule' }
          });

          // eslint-disable-next-line no-console
          apm.captureError(updateIncidentRuleResponse.error);
          return;
        }
      }

      setSaving(false);
      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Service created successfully'
      });

      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Service updated successfully'
      });

      history.push(`/synthetic/${routeParams.uid}`);
      return;
    }

    const multiFlowResponse = await createMultiFlow({ data: multiFlow });

    if (multiFlowResponse.error) {
      setSaving(false);
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: { message: 'Error on create multiflow' }
      });

      // eslint-disable-next-line no-console
      apm.captureError(multiFlowResponse.error);
      return;
    }

    const uid = multiFlowResponse.data?.createMultiFlow.uid;

    if (rules.incident.enable) {
      const incidentRules = useRuleManager
        ? incidentsNewRulesAdapter({
            incident: { ...rules.incident },
            entityUid: uid!,
            entity: RuleOriginEntityType.Multiflow
          })
        : incidentsRulesAdapter({
            incident: rules.incident,
            entityUid: uid!,
            entity: RuleOriginEntityType.Multiflow
          });

      const incidentRulesResponse = useRuleManager
        ? await createRule({
            rule: incidentRules
          })
        : await createIncidentRule({
            rule: incidentRules
          });

      if (incidentRulesResponse.error) {
        setSaving(false);
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Error on create incident rules' }
        });

        // eslint-disable-next-line no-console
        apm.captureError(incidentRulesResponse.error);
        return;
      }
    }

    setSaving(false);
    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Service created successfully'
    });

    history.push(`/synthetic/${uid}`);
  };

  useEffect(() => {
    reset(startValues);
  }, [reset, startValues]);

  useEffect(() => {
    if (fields.length === 0) {
      addMoreStep(stepDefaultValue);
    }
  }, [fields, addMoreStep]);

  const watchIntervalFailures = watch('rules.incident.intervalFailures');
  const watchFailuresToOpenIncident = watch('rules.incident.failuresToOpenIncident');
  const watchIntervalHits = watch('rules.incident.intervalHits');
  const watchHitsToCloseIncident = watch('rules.incident.hitsToCloseIncident');
  const watchIntervalCheck = watch('intervalCheck');

  useEffect(() => {
    trigger([
      'rules.incident.intervalFailures',
      'rules.incident.failuresToOpenIncident',
      'rules.incident.intervalHits',
      'rules.incident.hitsToCloseIncident',
      'intervalCheck'
    ]);
  }, [
    isValid,
    trigger,
    watchIntervalFailures,
    watchFailuresToOpenIncident,
    watchIntervalHits,
    watchHitsToCloseIncident,
    watchIntervalCheck
  ]);

  const hasPermissionToCreate = usePermission('MultiFlowController-post-/multiflow');
  const hasPermissionToEdit = usePermission('MultiFlowController-put-/multiflow');

  const isSubmitDisabled = (isEdit ? !hasPermissionToEdit : !hasPermissionToCreate) || !isDirty;

  return (
    <Box
      component="form"
      display="flex"
      flex={1}
      gridGap="2.5rem"
      flexDirection="column"
      position="relative"
      onSubmit={form.handleSubmit(handleSubmit)}>
      <Box>
        {(fetching || saving) && <LoadingOverlay />}

        <Typography className={classes.sectionTitle} variant="h4">
          Synthetic monitoring
        </Typography>

        <Box display="flex" gridGap="3rem">
          <Box flex={2}>
            <Box display="flex" gridGap="2rem" flexDirection="column">
              <BaseForm form={form} />
              <Form form={form} />
              <KeysForm />
            </Box>
          </Box>
          <Box flex={1}>
            <Typography className={classes.infoSectionTitle} variant="subtitle2">
              Configure Monitoring Info
            </Typography>
            <Typography className={classes.infoSectionDescription} variant="subtitle1">
              Insert the information for the resource you want to monitor. Create a Resource Name
              that best describes the resource. Choose the environment from the list, or click the
              "+ Agent" to install a new agent in an environment. Finally, insert the information
              from the specific resource you want to monitor.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Divider className={classes.sectionDivider} />

      <Box>
        <Box display="flex" gridGap="12rem">
          <Box display="flex" justifyContent="space-between" alignItems="center" flex={2}>
            <Typography variant="h4" className={classes.stepsTitle}>
              Configuration Steps
            </Typography>
          </Box>
          <Box flex={1}></Box>
        </Box>

        {fields.map((field, index) => {
          return (
            <Step
              field={field}
              form={form}
              index={index}
              removeStep={removeStep}
              key={field.stepsId}
            />
          );
        })}
        <Box display="flex" gridGap="3rem" mt={4}>
          <Box flex={2}>
            <Box display="flex" gridGap="2rem" flexDirection="column">
              <Button
                variant="contained"
                color="primary"
                onClick={() => addMoreStep(stepDefaultValue)}>
                ADD STEP
              </Button>
            </Box>
          </Box>
          <Box flex={1}></Box>
        </Box>
      </Box>

      {/* <Divider className={classes.sectionDivider} />

      <Box>
        <Typography className={classes.sectionTitle} variant="h4" color="secondary">
          Alert
        </Typography>
        <Box display="flex" gridGap="12rem">
          <Box flex={1}>
            <AlertForm form={form} />
          </Box>
          <Box flex={1}>
            <Typography className={classes.infoSectionTitle} variant="subtitle2" color="secondary">
              Configure Alert Threshold(s)
            </Typography>
            <Typography className={classes.infoSectionDescription} variant="subtitle1">
              Here you can set the thresholds of the resource which will trigger an alert, including
              the severity of the alert, so you can define when and how to act on it. Choose a
              preexisting team to be notified.
            </Typography>
          </Box>
        </Box>
      </Box> */}

      <Divider className={classes.sectionDivider} />

      <Box>
        <Typography className={classes.sectionTitle} variant="h4">
          Open automatic incident
        </Typography>
        <Box display="flex" gridGap="3rem">
          <Box flex={2}>
            <IncidentForm form={form} />
          </Box>
          <Box flex={1}>
            <Typography className={classes.infoSectionTitle} variant="subtitle2">
              Configure Incident Threshold(s)
            </Typography>
            <Typography className={classes.infoSectionDescription} variant="subtitle1">
              Here you can set the thresholds of the resource, which will open an automatic
              incident, including the severity, so you can define when and how to act on it. Choose
              a preexisting team to be notified.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box display="flex" gridGap="1rem">
        <Button variant="outlined" color="primary" onClick={() => history.goBack()}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={isSubmitDisabled}>
          {isEdit ? 'Save' : 'Create'} monitoring
        </Button>
      </Box>
    </Box>
  );
}
