import { useMemo } from 'react';

import { GetIncidentRuleToResourceQuery } from 'graphqlQueries/getIncidentRuleToResource';
import { useParams } from 'react-router';
import { Rule, RuleOriginEntityType } from 'types/external/AlertCenter';
import { MultiFlow } from 'types/external/MultiFlow';
import { useQuery } from 'urql';

import { useTeams } from 'hooks/queriesGraphQL/useTeams';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { GetRulesByEntityUidQuery } from 'hooks/useRule';
import { multiFlowStartValuesAdapter } from 'views/ServicesHub/adapters/edit/multiflow';
import { incidentsRulesAdapter } from 'views/ServicesHub/adapters/edit/rules/incident';

const GetMultiFlowStartValues = `#graphql
  query GetMultiFlowStartValues(
    $multiFlowUid: String!
  ) {
    multiflow(
      multiFlowUid: $multiFlowUid
    ) {
      id
      name
      uid
      environmentId
      interval
      steps {
        id
        name
        domain
        method
        timeout
        status
        domainSettings
        assertions {
          id
          source
          order
          comparison
          targetValue
          property
        }
      }
    }
  }
`;

export function useStartValues() {
  const { uid } = useParams<{ uid: string }>();

  const [{ data: multiFlowData, fetching: fetchingResource }] = useQuery<{ multiflow: MultiFlow }>({
    variables: { multiFlowUid: uid },
    query: GetMultiFlowStartValues,
    pause: !uid
  });
  const [{ data: incidentRuleData, fetching: fetchingIncidentRule }] = useQuery<{
    getIncidentRuleByEntityUid: Rule;
  }>({
    variables: { resourceUid: uid },
    query: GetIncidentRuleToResourceQuery,
    pause: !uid
  });
  const [{ data: rulesData, fetching: fetchingRule }] = useQuery<{ getRulesByEntityUid: Rule[] }>({
    query: GetRulesByEntityUidQuery,
    variables: { entityUid: uid, entity: RuleOriginEntityType.Multiflow },
    pause: !uid
  });

  const [{ data, fetching: fetchingGetTeams }] = useTeams();

  const fetching = fetchingResource || fetchingIncidentRule || fetchingGetTeams || fetchingRule;

  const multiflow = multiFlowData?.multiflow;

  const useRuleManager = useFeatureFlagUnleash('useRuleManagerAPI', {
    queryString: true
  });

  return useMemo(() => {
    const teamsFieldOptions =
      data?.getTeamsByOrgUid?.data?.map(team => ({
        value: String(team.id),
        label: team.name
      })) ?? [];

    if (!multiflow) return { startValues: undefined, fetching: false };

    const incidentRule = useRuleManager
      ? rulesData?.getRulesByEntityUid?.find(item =>
          item.actions.some(action => action.type !== 'timeout')
        )
      : incidentRuleData?.getIncidentRuleByEntityUid;
    const multiFlowStartValues = multiFlowStartValuesAdapter(multiflow);

    const incidentRuleStartValues = incidentsRulesAdapter(incidentRule, teamsFieldOptions);

    const startValues = {
      ...multiFlowStartValues,
      rules: {
        incident: incidentRuleStartValues,
        incidentRule
      }
    };

    return { startValues, fetching };
  }, [
    fetching,
    incidentRuleData?.getIncidentRuleByEntityUid,
    rulesData?.getRulesByEntityUid,
    multiflow,
    data?.getTeamsByOrgUid?.data,
    useRuleManager
  ]);
}
