import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStyles = makeStyles((theme: Theme) => ({
  alertTitle: {
    fontWeight: theme.typography.fontWeightMedium
  },
  paperProps: {
    width: '220px'
  },
  alertCause: {
    margin: theme.spacing(2, 0, 2),
    color: theme.palette.gray[500]
  },
  alertDateTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.gray[900]
  },
  alertCauseTitle: {
    margin: theme.spacing(2, 0, 2),
    color: theme.palette.gray[900],
    fontWeight: theme.typography.fontWeightMedium
  },
  ellipse: {
    width: '4px',
    height: '4px',
    backgroundColor: theme.palette.gray[700],
    borderRadius: '50%',
    marginRight: theme.spacing(1)
  },
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.green[50],
      color: theme.palette.green[400]
    }
  },
  alertDate: {
    color: theme.palette.gray[500]
  },
  menuButton: {
    marginLeft: 'auto',
    minWidth: 42,
    height: 34,
    border: '1px solid #595959',
    padding: theme.spacing(1),
    '& span': {
      width: 'fit-content'
    },
    '& svg': {
      width: '1.5rem',
      height: '1.5rem'
    }
  },
  alertDivider: {
    backgroundColor: theme.palette.gray[100],
    margin: theme.spacing(3.5, 0, 3.5)
  },
  filterBadgeContent: {
    backgroundColor: '#CDF4E2',
    color: '#515151',
    border: '1px solid #5FBC91',
    fontWeight: 500
  },
  textFilter: {
    fontWeight: 500,
    lineHeight: '26px',
    marginLeft: '3px'
  },
  buttonBackground: {
    backgroundColor: '#ECFDF5'
  },
  button: {
    border: '1px solid #5FBC91'
  }
}));
