import MainLayoutV5 from 'layouts/v5/Main';
import { AccountView } from 'views/Account';

import RouteWithLayoutV2 from '../components/RouteWithLayoutV2';

export const accountsRoutesV4WithLayoutV2 = [
  <RouteWithLayoutV2
    component={AccountView}
    exact
    layout={MainLayoutV5}
    key="/account"
    path="/account"
  />
];
