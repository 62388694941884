import { MouseEvent, useState } from 'react';

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  MenuItem,
  Typography,
  capitalize,
  useTheme
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { SSOIntegration } from 'types/external/Aragorn/SSOIntegration';

import { Menu } from 'components/Menu';
import { GoogleColored } from 'icons/GoogleColored';
import { Microsoft } from 'icons/Microsoft';

import { useStyles } from './styles';

type SSOIntegrationCardProps = {
  type: SSOIntegration['type'];
  connected?: boolean;
};

function getSSOIntegrationIcon(type: SSOIntegrationCardProps['type']) {
  switch (type) {
    case 'google':
      return <GoogleColored fontSize="large" />;
    case 'microsoft':
      return <Microsoft fontSize="large" />;
  }
}

function SSOIntegrationCardComponent({ type, connected = false }: SSOIntegrationCardProps) {
  const classes = useStyles();
  const theme = useTheme();

  const [menuActionAnchorEl, setMenuActionAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpenMenuActions = (event: MouseEvent<HTMLButtonElement>) => {
    setMenuActionAnchorEl(event.currentTarget);
  };

  const handleCloseMenuActions = () => {
    setMenuActionAnchorEl(null);
  };

  const createSSOIntegrationPagePath = `/organization/single-sign-on/new/${type}`;

  return (
    <Card className={classes.root}>
      <Link to={connected ? '#' : createSSOIntegrationPagePath} data-disabled={connected}>
        <CardContent className="card-content">
          <Box display="flex" alignItems="center" gridGap={theme.spacing()} mb={2}>
            {getSSOIntegrationIcon(type)}

            <Typography className={classes.title} variant="h4">
              SAML {capitalize(type)}
            </Typography>
          </Box>
          <Typography variant="caption">
            {capitalize(type)} SSO is a secure authentication method that allows a user to log in to
            a session granting access to various related applications and services.
          </Typography>
        </CardContent>
      </Link>

      <Divider className={classes.divider} />

      <CardActions className={classes.actions}>
        <Chip
          data-connected={connected}
          label={connected ? 'Connected' : 'Unconnected'}
          className="chip"
        />

        {/* Remove this conditional when SSO Integrations CRUD is implemented. */}
        {!connected && (
          <Button
            className={classes.menuActionsButton}
            variant="outlined"
            onClick={handleOpenMenuActions}
            color="primary">
            <MoreVert />
          </Button>
        )}
        <Menu anchorEl={menuActionAnchorEl} onClose={handleCloseMenuActions} placement="bottom">
          {/* Uncomment when SSO Integrations CRUD is implemented. */}
          {/* {connected && (
            <>
              <Link to={`${window.location.pathname}${window.location.search}`}>
                <MenuItem>Edit Workspace</MenuItem>
              </Link>
              <MenuItem>Delete Workspace</MenuItem>
            </>
          )} */}
          {!connected && (
            <Link to={createSSOIntegrationPagePath}>
              <MenuItem>Integrate now</MenuItem>
            </Link>
          )}
        </Menu>
      </CardActions>
    </Card>
  );
}

export const SSOIntegrationCard = SSOIntegrationCardComponent;
