import MainLayoutV5 from 'layouts/v5/Main';
import { LogOutView } from 'views/LogOut';
import SelectOrg from 'views/V4/SelectOrg';

import RouteWithLayoutV2 from '../components/RouteWithLayoutV2';

export const authRoutesWithLayoutV2 = [
  <RouteWithLayoutV2
    component={SelectOrg}
    exact
    layout={MainLayoutV5}
    key="/select-org"
    path="/select-org"
  />,
  <RouteWithLayoutV2
    exact
    component={LogOutView}
    layout={MainLayoutV5}
    path="/logout"
    key="/logout"
  />
];
