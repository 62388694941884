import { Box, Button, Container, List, ListItem, Typography, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { MenuSidebar } from 'types/directus';

import { LockChip } from 'components/LockChip';
import { checkIsActive } from 'helpers/checkMenuIsActive';
import { composeNavigationLink } from 'helpers/composeNavigationLink';
import { isExternalUrl } from 'helpers/isExternalUrl';
import { useFeaturePlan } from 'hooks/useFeaturePlan';
import { useLoggedUserRole } from 'hooks/useLoggedUserRole';
import { useMenuItems } from 'layouts/V4/components/Sidebar/components/SidebarList/useMenuItems';

import { ToolbarMenu } from './Menu';

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.grey[100],
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(0, 8.75)
  },
  navbar: {
    display: 'flex',

    '&:has(> :first-child[data-active=false])': {
      marginLeft: -theme.spacing(2)
    },

    '& > li[data-active=true]': {
      borderTop: `6px solid ${theme.palette.primary.light}`,
      '& p': {
        color: theme.palette.grey[900],
        fontWeight: 'bold'
      }
    },

    '& li': {
      width: 'fit-content',
      padding: 0
    },
    '& a': {
      height: '100%'
    },
    '& button, a[role="button"]': {
      textTransform: 'initial',
      ...theme.typography.body1,
      color: theme.palette.text.primary,
      padding: theme.spacing(2),
      borderRadius: 0,
      height: '100%'
    },
    '& [role="button"] [class*=MuiButton-label]': {
      gap: '1rem'
    },
    '& button:hover p': {
      textShadow: '0px 0px', // This is a hack to bold effect without break layout
      color: theme.palette.grey[900]
    },
    '& button + div': {
      visibility: 'hidden',
      marginTop: 0
    },
    '& button:hover + div, & button + div:hover': {
      visibility: 'visible'
    }
  }
}));

export function Navbar() {
  const classes = useStyles();

  const [result] = useLoggedUserRole();

  const role = result.data?.role.name;

  const { menuItems: data } = useMenuItems();

  const menuItemsFilteredByRole = role ? data.filter(item => item.allowedRoles.includes(role)) : [];

  const menuItems = menuItemsFilteredByRole;

  return (
    <Box className={classes.container}>
      <Container maxWidth="xl" disableGutters>
        <List component="nav" className={classes.navbar} disablePadding>
          {menuItems?.map(menuItem => (
            <NavBarMenuItem key={menuItem.id} menuItem={menuItem} />
          ))}
        </List>
      </Container>
    </Box>
  );
}

function NavBarMenuItem({ menuItem }: { menuItem: MenuSidebar }) {
  const isActive = checkIsActive({ menuItem });

  const hasChildren = menuItem.children.length > 0;

  const isFeaturePlanEnabled = useFeaturePlan(menuItem.planFeature, { placeholder: true });

  const isPageAccessibleByPlan = menuItem.planFeature ? isFeaturePlanEnabled : true;

  return (
    <ListItem key={menuItem.id} id={`list-item-${menuItem.id}`} data-active={isActive}>
      {!hasChildren && (
        <Button
          component={Link}
          disableRipple
          to={composeNavigationLink(menuItem.href ?? '')}
          rel="noopener noreferrer"
          target={isExternalUrl(menuItem.href ?? '') ? '_blank' : '_self'}
          disabled={!isPageAccessibleByPlan}>
          <Typography>{menuItem.title}</Typography>
          {!isPageAccessibleByPlan && <LockChip />}
        </Button>
      )}

      {hasChildren && (
        <>
          <Button disableRipple>
            <Typography>{menuItem.title}</Typography>
          </Button>
          <ToolbarMenu anchorElId={`list-item-${menuItem.id}`} menuItems={menuItem.children} />
        </>
      )}
    </ListItem>
  );
}
