import { useMemo } from 'react';

import { apm } from '@elastic/apm-rum';
import { GetIncidentRuleToResourceQuery } from 'graphqlQueries/getIncidentRuleToResource';
import { useParams } from 'react-router';
import { Rule, RuleOriginEntityType } from 'types/external/AlertCenter';
import { useQuery } from 'urql';

import { useResource } from 'hooks/queriesGraphQL/useResource';
import { useTeams } from 'hooks/queriesGraphQL/useTeams';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { GetRulesByEntityUidQuery } from 'hooks/useRule';
import { adapters } from 'views/ServicesHub/adapters/edit';
import { incidentsRulesAdapter } from 'views/ServicesHub/adapters/edit/rules/incident';
import { rulesAdapter } from 'views/ServicesHub/adapters/edit/rules/timeout';

export function useStartValues() {
  const { uid } = useParams<{ uid: string }>();
  const [{ data: resourceData, fetching: fetchingResource }] = useResource(uid);
  const [{ data: incidentRuleData, fetching: fetchingIncidentRule }] = useQuery<{
    getIncidentRuleByEntityUid: Rule;
  }>({
    variables: { resourceUid: uid },
    query: GetIncidentRuleToResourceQuery,
    pause: !uid
  });

  const [{ data: rulesData, fetching: fetchingRule }] = useQuery<{ getRulesByEntityUid: Rule[] }>({
    query: GetRulesByEntityUidQuery,
    variables: { entityUid: uid, entity: RuleOriginEntityType.Resource },
    pause: !uid
  });

  const [{ data, fetching: fetchingGetTeams }] = useTeams();

  const fetching = fetchingResource || fetchingIncidentRule || fetchingGetTeams || fetchingRule;

  const resource = resourceData?.resource;
  const resourceMethod = resource?.method.toLowerCase() as keyof typeof adapters;

  const useRuleManager = useFeatureFlagUnleash('useRuleManagerAPI', {
    queryString: true
  });

  return useMemo(() => {
    const teamsFieldOptions =
      data?.getTeamsByOrgUid?.data?.map(team => ({
        value: String(team.id),
        label: team.name
      })) ?? [];

    if (!resourceMethod || !resource) return { startValues: undefined, fetching: false };

    if (!(resourceMethod in adapters)) {
      apm.captureError(`Method ${resourceMethod} not found in adapters`);
      return { startValues: undefined, fetching: false };
    }

    const incidentRule = useRuleManager
      ? rulesData?.getRulesByEntityUid?.find(item =>
          item.actions.some(action => action.type !== 'timeout')
        )
      : incidentRuleData?.getIncidentRuleByEntityUid;
    const resourceStartValues = adapters[resourceMethod](resource);

    const timeoutRule = rulesData?.getRulesByEntityUid?.find(item =>
      item.actions.some(action => action.type === 'timeout')
    );

    const incidentRuleStartValues = incidentsRulesAdapter(incidentRule, teamsFieldOptions);

    const ruleStartValues = rulesAdapter(timeoutRule, teamsFieldOptions);

    const startValues = {
      ...resourceStartValues,
      rules: {
        incident: incidentRuleStartValues,
        incidentRule,
        timeout: ruleStartValues,
        timeoutRule: timeoutRule
      }
    };

    return { startValues, fetching };
  }, [
    data?.getTeamsByOrgUid?.data,
    resourceMethod,
    resource,
    incidentRuleData?.getIncidentRuleByEntityUid,
    rulesData?.getRulesByEntityUid,
    fetching,
    useRuleManager
  ]);
}
