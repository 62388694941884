import MainLayoutV5 from 'layouts/v5/Main';
import { AuthorizedRoute } from 'routes/components/AuthorizedRoute';
import UserEditView from 'views/Users/Edit';
import UserListView from 'views/Users/List';
import UserNewView from 'views/Users/New';

export const usersRoutesWithLayoutV2 = [
  <AuthorizedRoute
    component={UserListView}
    exact
    layout={MainLayoutV5}
    key="/users"
    path="/users"
  />,
  <AuthorizedRoute
    component={UserNewView}
    exact
    layout={MainLayoutV5}
    key="/users/new"
    path="/users/new"
    featureTarget="AccountController-post-/accounts"
  />,
  <AuthorizedRoute
    component={UserEditView}
    exact
    layout={MainLayoutV5}
    key="/users/:id/edit"
    path="/users/:id/edit"
    featureTarget="AccountController-put-/accounts"
  />
];
