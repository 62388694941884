import { useState, useEffect } from 'react';

import { apm } from '@elastic/apm-rum';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Divider, Typography } from '@material-ui/core';
import { AddSecretMutation } from 'graphqlQueries/addSecret';
import { CreateIncidentRuleMutation } from 'graphqlQueries/createIncidentRule';
import { CreateMultipleSecretsMutation } from 'graphqlQueries/createMultipleSecrets';
import { CreateResourceMutation } from 'graphqlQueries/createResource';
import { DeleteRuleMutation } from 'graphqlQueries/deleteRule';
import { UpdateIncidentRuleMutation } from 'graphqlQueries/updateIncidentRule';
import { UpdateResourceMutation } from 'graphqlQueries/updateResource';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Rule, RuleOriginEntityType } from 'types/external/AlertCenter';
import { Resource } from 'types/external/Resource';
import { useMutation } from 'urql';
import { v4 as uuidv4 } from 'uuid';

import getCapitalizeFirstLetter from 'common/getCapitalizeFirstLetter';
import LoadingOverlay from 'componentsV3/LoadingOverlay';
import useQueryParams from 'helpers/useQueryParams';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { usePermission } from 'hooks/usePermission';
import useRule from 'hooks/useRule';
import actions from 'redux/actions';
import {
  incidentsRulesAdapter,
  updateIncidentRuleAdapter
} from 'views/ServicesHub/adapters/new/rules/incident';
import {
  incidentsNewRulesAdapter,
  updateNewIncidentRuleAdapter
} from 'views/ServicesHub/adapters/new/rules/ruleIncident';
import { rulesAdapter, updateRuleAdapter } from 'views/ServicesHub/adapters/new/rules/timeout';
import { LayoutType } from 'views/ServicesHub/forms';
import { TimeoutRuleFormData } from 'views/ServicesHub/forms/monitoring/commom/Timeout';

import { adapters } from '../../adapters/new';
import { AlertFormData } from '../../forms/monitoring/commom/Alert';
import { BaseForm, MonitoringBaseForm } from '../../forms/monitoring/commom/Base';
import { IncidentFormData } from '../../forms/monitoring/commom/Incident';

import { getDefaultValuesByType } from './getDefaultValuesByType';
import { getSchemaByType } from './getSchemaByType';
import { RuleConfigSection } from './RuleConfigSection';
import { useStyles } from './styles';
import { useStartValues } from './useStartValues';

export type CommonsFormsData = MonitoringBaseForm & {
  password?: string;
  secretAccessKey?: string;
  apiKey?: string;
};

export type MonitoringCommomData = CommonsFormsData & {
  rules: Partial<IncidentFormData & AlertFormData & TimeoutRuleFormData>;
  timeoutRule?: TimeoutRuleFormData;
};

export function MonitoringLayout({ Form, isEdit = false }: LayoutType) {
  const classes = useStyles();

  const useCreateMultipleSecrets = useFeatureFlagUnleash('useCreateMultipleSecrets', {
    queryString: true
  });

  const useRuleManager = useFeatureFlagUnleash('useRuleManagerAPI', {
    queryString: true
  });

  const { startValues, fetching: fetchingStartValues } = useStartValues();
  const routeParams = useParams<{ uid: string }>();

  const queryParams = useQueryParams();

  const formType = (
    startValues?.method ??
    (queryParams.get('type') || '')
  ).toLowerCase() as keyof typeof adapters;

  const schema = getSchemaByType(formType);

  const defaultValues = getDefaultValuesByType(formType);

  const form = useForm<any>({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues
  });

  const {
    reset,
    watch,
    formState: { isValid, isDirty }
  } = form;

  const domainType = watch('domainType');

  useEffect(() => {
    reset(startValues);
  }, [reset, startValues]);

  const history = useHistory();

  const dispatch = useDispatch();

  const [{ fetching: creatingResource }, createResource] = useMutation<{
    createResource: Resource;
  }>(CreateResourceMutation);
  const [{ fetching: updatingResource }, updateResource] = useMutation(UpdateResourceMutation);
  const [{ fetching: creatingIncidentRule }, createIncidentRule] = useMutation(
    CreateIncidentRuleMutation
  );
  const [{ fetching: updatingIncidentRule }, updateIncidentRule] = useMutation(
    UpdateIncidentRuleMutation
  );

  const [{ fetching: deletingRule }, deleteRule] = useMutation(DeleteRuleMutation);
  const [{ fetching: fetchingCreateSecret }, addSecretMutation] = useMutation(AddSecretMutation);
  const [{ fetching: fetchingCreateMultipleSecrets }, createMultipleSecrets] = useMutation(
    CreateMultipleSecretsMutation
  );

  const { createRule, deleteRule: deleteRuleEvent, updateRule, isFetchingRule } = useRule();

  const fetching =
    fetchingStartValues ||
    creatingResource ||
    updatingResource ||
    creatingIncidentRule ||
    deletingRule ||
    updatingIncidentRule ||
    fetchingCreateSecret ||
    fetchingCreateMultipleSecrets ||
    isFetchingRule;

  const hasIncidentRule = Boolean(startValues?.rules?.incident?.enable);
  const hasTimeoutRule = Boolean(startValues?.rules?.timeout?.enable);
  const [saving, setSaving] = useState(false);

  const validateSecret = async (
    value: string,
    formType: keyof typeof adapters,
    fieldName: string
  ) => {
    const secretRegex = /{{\..+}}/;
    const isSecret = secretRegex.test(value);

    if (!isSecret) {
      const hash = Number(new Date());

      const createdSecret = await addSecretMutation({
        name: `${fieldName}-${getCapitalizeFirstLetter(formType)}-${hash}`,
        value: value
      });

      if (createdSecret.error) {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: `Error on create secret for ${fieldName}` }
        });

        // eslint-disable-next-line no-console
        apm.captureError(createdSecret.error);
        return { error: createdSecret.error };
      }
      return `{{.${createdSecret?.data?.addSecret?.name}}}`;
    }
    return value;
  };

  const validatedHostsAndPortsArray = async (
    hostsAndPorts: any
  ): Promise<{ key: string; value: string }[] | { error: string } | null> => {
    if (!hostsAndPorts) return null;
    try {
      return await Promise.all(
        hostsAndPorts.map(async (broker: { key: string; value: any }) => {
          const validatedHost = await validateSecret(broker.key, formType, 'Host');

          if (typeof validatedHost !== 'string' && validatedHost?.error) {
            throw new Error(validatedHost?.error.message);
          }

          return { key: validatedHost, value: broker.value };
        })
      );
    } catch (error) {
      return { error: (error as Error).message };
    }
  };

  const validateResourceData = async (resourceData: any) => {
    const password = resourceData.password;
    const username = resourceData.username;
    const user = resourceData.user;
    const domain = resourceData.domain;
    const host = resourceData.host;
    const database = resourceData.database;
    const accessKey = resourceData.accessKey;
    const secretAccessKey = resourceData.secretAccessKey;
    const apiKey = resourceData.apiKey;
    const brokers = resourceData.brokers;
    const hosts = resourceData.hosts;

    const validatedBrokers = await validatedHostsAndPortsArray(brokers);
    const validatedHostsList = await validatedHostsAndPortsArray(hosts);

    const isErrorObject = (param: any): param is { error: string } => {
      if (typeof param === 'object' && param?.error) {
        return true;
      }
      return false;
    };

    const validatedPassword = password
      ? await validateSecret(password, formType, 'Password')
      : password;

    const validatedUsername = username
      ? await validateSecret(username, formType, 'Username')
      : username;

    const validatedUser = user ? await validateSecret(user, formType, 'User') : user;

    const validatedDomain = domain ? await validateSecret(domain, formType, 'Domain') : domain;

    const validatedHost = host ? await validateSecret(host, formType, 'Host') : host;

    const validatedDatabse = database
      ? await validateSecret(database, formType, 'Database')
      : database;

    const validadeAccessKey = accessKey
      ? await validateSecret(accessKey, formType, 'Access-Key')
      : accessKey;

    const validadeSecretAccessKey = secretAccessKey
      ? await validateSecret(secretAccessKey, formType, 'Secret-Access-Key')
      : secretAccessKey;

    const validatedApiKey = apiKey ? await validateSecret(apiKey, formType, 'API-Key') : apiKey;

    if (
      (typeof validatedPassword !== 'string' && validatedPassword?.error) ||
      (typeof validatedUsername !== 'string' && validatedUsername?.error) ||
      (typeof validatedUser !== 'string' && validatedUser?.error) ||
      (typeof validatedDomain !== 'string' && validatedDomain?.error) ||
      (typeof validatedHost !== 'string' && validatedHost?.error) ||
      (typeof validatedDatabse !== 'string' && validatedDatabse?.error) ||
      (typeof validadeSecretAccessKey !== 'string' && validadeSecretAccessKey?.error) ||
      (typeof validadeAccessKey !== 'string' && validadeAccessKey?.error) ||
      (typeof validatedApiKey !== 'string' && validatedApiKey?.error) ||
      (isErrorObject(validatedBrokers) && validatedBrokers?.error) ||
      (isErrorObject(validatedHostsList) && validatedHostsList?.error)
    ) {
      return { error: 'Fail to validate secrets' };
    }

    const resourceDataWithSecretedPasswords = () => {
      return {
        ...(resourceData as any),
        password: validatedPassword,
        username: validatedUsername,
        user: validatedUser,
        domain: validatedDomain,
        host: validatedHost,
        database: validatedDatabse,
        accessKey: validadeAccessKey,
        secretAccessKey: validadeSecretAccessKey,
        apiKey: validatedApiKey,
        brokers: validatedBrokers,
        hosts: validatedHostsList
      };
    };

    return resourceDataWithSecretedPasswords();
  };

  const validateResourceDataV2 = async (resourceData: any) => {
    const password = resourceData.password;
    const username = resourceData.username;
    const user = resourceData.user;
    const domain = resourceData.domain;
    const host = resourceData.host;
    const database = resourceData.database;
    const accessKey = resourceData.accessKey;
    const secretAccessKey = resourceData.secretAccessKey;
    const apiKey = resourceData.apiKey;
    const brokers = resourceData.brokers;
    const hosts = resourceData.hosts;

    const fieldsToCreateSecrets = [];

    const brokersWithUid = brokers
      ? brokers.map((broker: { key: string; value: number }) => {
          return { ...broker, uid: uuidv4() };
        })
      : undefined;

    const hostsWithUid = hosts
      ? hosts.map((host: { key: string; value: number }) => {
          return { ...host, uid: uuidv4() };
        })
      : undefined;

    if (brokersWithUid) {
      brokersWithUid.forEach(
        (broker: { key: string; value: number; uid: string }, index: number) => {
          const timestamp = Number(new Date());
          if (!isSecret(broker.key)) {
            fieldsToCreateSecrets.push({
              fieldName: 'Brokers',
              value: broker.key,
              name: `Host-${index}-${getCapitalizeFirstLetter(formType)}-${timestamp}`,
              port: broker.value,
              uid: broker.uid
            });
          }
        }
      );
    }

    if (hostsWithUid) {
      hostsWithUid.forEach((host: { key: string; value: number; uid: string }, index: number) => {
        const timestamp = Number(new Date());
        if (!isSecret(host.key)) {
          fieldsToCreateSecrets.push({
            fieldName: 'Brokers',
            value: host.key,
            name: `Host-${index}-${getCapitalizeFirstLetter(formType)}-${timestamp}`,
            port: host.value,
            uid: host.uid
          });
        }
      });
    }

    if (password && !isSecret(password)) {
      fieldsToCreateSecrets.push({
        fieldName: 'Password',
        value: password,
        name: `Password-${getCapitalizeFirstLetter(formType)}-${Number(new Date())}`
      });
    }

    if (username && !isSecret(username)) {
      fieldsToCreateSecrets.push({
        fieldName: 'Username',
        value: username,
        name: `Username-${getCapitalizeFirstLetter(formType)}-${Number(new Date())}`
      });
    }

    if (user && !isSecret(user)) {
      fieldsToCreateSecrets.push({
        fieldName: 'User',
        value: user,
        name: `User-${getCapitalizeFirstLetter(formType)}-${Number(new Date())}`
      });
    }

    if (domain && !isSecret(domain)) {
      fieldsToCreateSecrets.push({
        fieldName: 'Domain',
        value: domain,
        name: `Domain-${getCapitalizeFirstLetter(formType)}-${Number(new Date())}`
      });
    }

    if (host && !isSecret(host)) {
      fieldsToCreateSecrets.push({
        fieldName: 'Host',
        value: host,
        name: `Host-${getCapitalizeFirstLetter(formType)}-${Number(new Date())}`
      });
    }

    if (database && !isSecret(database)) {
      fieldsToCreateSecrets.push({
        fieldName: 'Database',
        value: database,
        name: `Database-${getCapitalizeFirstLetter(formType)}-${Number(new Date())}`
      });
    }

    if (accessKey && !isSecret(accessKey)) {
      fieldsToCreateSecrets.push({
        fieldName: 'AccessKey',
        value: accessKey,
        name: `AccessKey-${getCapitalizeFirstLetter(formType)}-${Number(new Date())}`
      });
    }

    if (secretAccessKey && !isSecret(secretAccessKey)) {
      fieldsToCreateSecrets.push({
        fieldName: 'SecretAccessKey',
        value: secretAccessKey,
        name: `SecretAccessKey-${getCapitalizeFirstLetter(formType)}-${Number(new Date())}`
      });
    }

    if (apiKey && !isSecret(apiKey)) {
      fieldsToCreateSecrets.push({
        fieldName: 'ApiKey',
        value: apiKey,
        name: `ApiKey-${getCapitalizeFirstLetter(formType)}-${Number(new Date())}`
      });
    }

    const secretsToCreate = fieldsToCreateSecrets.map(field => {
      return {
        name: field.name,
        value: field.value
      };
    });

    if (secretsToCreate.length > 0) {
      const response = await createMultipleSecrets({ secrets: secretsToCreate });

      if (response.error) {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: `Error on create secret` }
        });

        // eslint-disable-next-line no-console
        apm.captureError(response.error);
        return { error: response.error };
      }
    }

    const foundPassword = password
      ? findFieldInSecrets('Password', fieldsToCreateSecrets)
      : undefined;
    const foundUsername = username
      ? findFieldInSecrets('Username', fieldsToCreateSecrets)
      : undefined;
    const foundUser = user ? findFieldInSecrets('User', fieldsToCreateSecrets) : undefined;
    const foundDomain = domain ? findFieldInSecrets('Domain', fieldsToCreateSecrets) : undefined;
    const foundHost = host ? findFieldInSecrets('Host', fieldsToCreateSecrets) : undefined;
    const foundDatabase = database
      ? findFieldInSecrets('Database', fieldsToCreateSecrets)
      : undefined;
    const foundAccessKey = accessKey
      ? findFieldInSecrets('AccessKey', fieldsToCreateSecrets)
      : undefined;
    const foundSecretAccessKey = secretAccessKey
      ? findFieldInSecrets('SecretAccessKey', fieldsToCreateSecrets)
      : undefined;
    const foundApiKey = apiKey ? findFieldInSecrets('ApiKey', fieldsToCreateSecrets) : undefined;

    const resourceDataWithSecreted = (
      fieldsToCreateSecrets: {
        name: string;
        value: string;
        fieldName: string;
        port?: number;
        uid?: string;
      }[]
    ) => {
      return {
        ...(resourceData as any),
        password: foundPassword ? `{{.${foundPassword?.name}}}` : password,
        username: foundUsername ? `{{.${foundUsername?.name}}}` : username,
        user: foundUser ? `{{.${foundUser?.name}}}` : user,
        domain: foundDomain ? `{{.${foundDomain?.name}}}` : domain,
        host: foundHost ? `{{.${foundHost?.name}}}` : host,
        database: foundDatabase ? `{{.${foundDatabase?.name}}}` : database,
        accessKey: foundAccessKey ? `{{.${foundAccessKey?.name}}}` : accessKey,
        secretAccessKey: foundSecretAccessKey
          ? `{{.${foundSecretAccessKey?.name}}}`
          : secretAccessKey,
        apiKey: foundApiKey ? `{{.${foundApiKey?.name}}}` : apiKey,
        brokers: brokersWithUid
          ? findHostsInSecrets(brokersWithUid, fieldsToCreateSecrets)
          : brokers,
        hosts: hostsWithUid ? findHostsInSecrets(hostsWithUid, fieldsToCreateSecrets) : hosts
      };
    };
    return resourceDataWithSecreted(fieldsToCreateSecrets);
  };

  const isSecret = (item: string) => {
    const secretRegex = /{{\..+}}/;
    const isSecret = secretRegex.test(item);

    if (!isSecret) return false;
    return true;
  };

  const findFieldInSecrets = (
    fieldName: string,
    fieldsToCreateSecrets: { name: string; value: string; fieldName: string }[]
  ) => {
    const findedField = fieldsToCreateSecrets.find(field => {
      return field.fieldName === fieldName;
    });
    return findedField ? findedField : undefined;
  };

  const findHostsInSecrets = (
    originalArray: { key: string; value: number; uid: string }[],
    fieldsToCreateSecrets: {
      name: string;
      value: string;
      fieldName: string;
      uid?: string;
      port?: number;
    }[]
  ) => {
    const newBrokersList = originalArray.map(item => {
      const newBroker = fieldsToCreateSecrets.find(field => {
        return field.uid === item.uid;
      });
      return newBroker
        ? { key: `{{.${newBroker.name}}}`, value: newBroker.port }
        : { key: item.key, value: item.value };
    });

    return newBrokersList;
  };

  const handleSubmit = async (data: MonitoringCommomData) => {
    setSaving(true);
    const { rules, ...resourceData } = data;
    const resourceValidated = useCreateMultipleSecrets
      ? await validateResourceDataV2(resourceData)
      : await validateResourceData(resourceData);

    if (resourceValidated?.error) {
      return;
    }

    const resource = adapters[formType](resourceValidated as any);

    if (isEdit && startValues) {
      const { origin, type, ...data } = resource;

      const resourceResponse = await updateResource({
        resource: { ...data, uid: routeParams.uid }
      });

      if (resourceResponse.error) {
        setSaving(false);
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Error on update service' }
        });

        // eslint-disable-next-line no-console
        console.error(resourceResponse.error);
        apm.captureError(resourceResponse.error);
        return;
      }

      if (!hasIncidentRule && rules?.incident?.enable) {
        const incidentRules = useRuleManager
          ? incidentsNewRulesAdapter({
              incident: { ...rules.incident },
              entityUid: routeParams.uid,
              entity: RuleOriginEntityType.Resource
            })
          : incidentsRulesAdapter({
              incident: { ...rules.incident },
              entityUid: routeParams.uid,
              entity: RuleOriginEntityType.Resource
            });

        const incidentRulesResponse = useRuleManager
          ? await createRule({
              rule: incidentRules
            })
          : await createIncidentRule({
              rule: incidentRules
            });

        if (incidentRulesResponse.error) {
          setSaving(false);
          dispatch({
            type: actions.ENTITY_ERROR,
            payload: { message: 'Error on create incident rules' }
          });

          // eslint-disable-next-line no-console
          console.error(incidentRulesResponse.error);
          apm.captureError(incidentRulesResponse.error);
          return;
        }
      }

      if (hasIncidentRule && !rules?.incident?.enable) {
        const incidentRulesResponse = useRuleManager
          ? await deleteRuleEvent({
              ruleUid: startValues.rules.incident.uid
            })
          : await deleteRule({
              ruleUid: startValues.rules.incident.uid
            });

        if (incidentRulesResponse.error) {
          setSaving(false);
          dispatch({
            type: actions.ENTITY_ERROR,
            payload: { message: 'Error on delete incident rule' }
          });

          // eslint-disable-next-line no-console
          console.error(incidentRulesResponse.error);
          apm.captureError(incidentRulesResponse.error);
          return;
        }
      }

      if (hasIncidentRule && rules?.incident?.enable) {
        const updateIncidentRuleData = useRuleManager
          ? updateNewIncidentRuleAdapter({
              incidentRule: startValues.rules.incidentRule as Rule,
              ruleFormData: {
                ...rules.incident,
                entity: RuleOriginEntityType.Resource,
                entityUid: routeParams.uid
              }
            })
          : updateIncidentRuleAdapter({
              incidentRule: startValues.rules.incidentRule as Rule,
              incident: rules.incident
            });

        const updateIncidentRuleResponse = useRuleManager
          ? await updateRule({
              data: updateIncidentRuleData
            })
          : await updateIncidentRule({
              data: updateIncidentRuleData
            });

        if (updateIncidentRuleResponse.error) {
          setSaving(false);
          dispatch({
            type: actions.ENTITY_ERROR,
            payload: { message: 'Error on update the incident rule' }
          });

          // eslint-disable-next-line no-console
          console.error(updateIncidentRuleResponse.error);
          apm.captureError(updateIncidentRuleResponse.error);
          return;
        }
      }

      if (hasTimeoutRule && !rules.timeout?.enable && resource.type === 'api') {
        const incidentRulesResponse = await deleteRuleEvent({
          ruleUid: startValues.rules.timeoutRule?.uid
        });

        if (incidentRulesResponse.error) {
          setSaving(false);
          dispatch({
            type: actions.ENTITY_ERROR,
            payload: { message: 'Error on delete timeout rule' }
          });

          // eslint-disable-next-line no-console
          console.error(incidentRulesResponse.error);
          apm.captureError(incidentRulesResponse.error);
          return;
        }
      }

      if (hasTimeoutRule && rules.timeout?.enable) {
        const updateRulePayload = updateRuleAdapter({
          ruleFormData: {
            ...rules.timeout,
            entity: RuleOriginEntityType.Resource,
            entityUid: routeParams.uid
          },
          rule: startValues.rules.timeoutRule!
        });

        const updateRuleResponse = await updateRule({
          data: updateRulePayload
        });

        if (updateRuleResponse.error) {
          setSaving(false);
          dispatch({
            type: actions.ENTITY_ERROR,
            payload: { message: 'Error on update the timeout rule' }
          });

          // eslint-disable-next-line no-console
          console.error(updateRuleResponse.error);
          apm.captureError(updateRuleResponse.error);
          return;
        }
      }

      if (!hasTimeoutRule && rules.timeout?.enable) {
        const rule = rulesAdapter({
          timeout: rules.timeout,
          entityUid: routeParams.uid,
          entity: RuleOriginEntityType.Resource
        });

        const rulesResponse = await createRule({ rule });

        if (rulesResponse.error) {
          setSaving(false);
          dispatch({
            type: actions.ENTITY_ERROR,
            payload: { message: 'Error on create rule' }
          });

          // eslint-disable-next-line no-console
          console.error(rulesResponse.error);
          apm.captureError(rulesResponse.error);
          return;
        }
      }

      setSaving(false);
      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Service created successfully'
      });

      history.push(`/monitoring/${routeParams.uid}`);
      return;
    }

    const resourceResponse = await createResource({ resource });

    if (resourceResponse.error) {
      setSaving(false);
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: { message: 'Error on create service' }
      });

      // eslint-disable-next-line no-console
      console.error(resourceResponse.error);
      apm.captureError(resourceResponse.error);
      return;
    }

    const uid = resourceResponse.data?.createResource.uid;

    if (rules?.incident?.enable) {
      const incidentRules = useRuleManager
        ? incidentsNewRulesAdapter({
            incident: { ...rules.incident },
            entityUid: uid!,
            entity: RuleOriginEntityType.Resource
          })
        : incidentsRulesAdapter({
            incident: { ...rules.incident },
            entityUid: uid!,
            entity: RuleOriginEntityType.Resource
          });

      const incidentRulesResponse = useRuleManager
        ? await createRule({
            rule: incidentRules
          })
        : await createIncidentRule({
            rule: incidentRules
          });

      if (incidentRulesResponse.error) {
        setSaving(false);
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Error on create incident rules' }
        });

        // eslint-disable-next-line no-console
        console.error(incidentRulesResponse.error);
        apm.captureError(incidentRulesResponse.error);
        return;
      }
    }

    if (rules.timeout?.enable) {
      const rule = rulesAdapter({
        timeout: rules.timeout,
        entityUid: uid!,
        entity: RuleOriginEntityType.Resource
      });

      const rulesResponse = await createRule({ rule });

      if (rulesResponse.error) {
        setSaving(false);
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Error on create rule' }
        });

        // eslint-disable-next-line no-console
        console.error(rulesResponse.error);
        apm.captureError(rulesResponse.error);
        return;
      }
    }

    setSaving(false);
    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Service created successfully'
    });

    history.push(`/monitoring/${uid}`);
  };

  const hasPermissionCreate = usePermission('ResourceController-post-/resource');
  const hasPermissionEdit = usePermission('ResourceController-put-/resources/:uid');

  const isSubmitDisabled =
    (isEdit ? !hasPermissionEdit : !hasPermissionCreate) || !isDirty || !isValid;

  return (
    <Box
      component="form"
      display="flex"
      flex={1}
      gridGap="2.5rem"
      flexDirection="column"
      onSubmit={form.handleSubmit(handleSubmit)}
      position="relative">
      <Box>
        {(fetching || saving) && <LoadingOverlay />}

        <Typography className={classes.sectionTitle} variant="h4">
          Monitoring
        </Typography>

        <Box display="flex" gridGap="3rem">
          <Box flex={2}>
            <Box display="flex" gridGap="2rem" flexDirection="column">
              <BaseForm form={form} domainType={domainType} />
              <Form form={form} domainType={domainType} />
            </Box>
          </Box>
          <Box flex={1}>
            <Typography className={classes.infoSectionTitle} variant="subtitle2">
              Configure Monitoring Info
            </Typography>
            <Typography className={classes.infoSectionDescription} variant="subtitle1">
              Insert the information for the resource you want to monitor. Create a Resource Name
              that best describes the resource. Choose the environment from the list, or click the
              "+ Monitoring" to install a new agent in an environment. Finally, insert the
              information from the specific resource you want to monitor.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Divider className={classes.sectionDivider} />

      <RuleConfigSection formType={formType} form={form} />

      <Box display="flex" gridGap="1rem">
        <Button variant="outlined" color="primary" onClick={() => history.goBack()}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={isSubmitDisabled}>
          {isEdit ? 'Save' : 'Create'} monitoring
        </Button>
      </Box>
    </Box>
  );
}
