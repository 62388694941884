import { useState, useEffect } from 'react';

import { Box, Typography, InputAdornment, IconButton, Fade } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import copy from 'clipboard-copy';

import { Input } from 'views/ServicesHub/components/Input';

type Props = {
  title: string;
  url?: string;
  onChangeToken?: (token: string) => void;
};

export const InputCopyUrl = ({ url, title }: Props) => {
  const [copiedSuccessfully, setCopiedSuccessfully] = useState(false);

  useEffect(() => {
    if (copiedSuccessfully) {
      setTimeout(() => {
        setCopiedSuccessfully(false);
      }, 300);
    }
  }, [copiedSuccessfully, setCopiedSuccessfully]);

  return (
    <Box mb={5}>
      <Box mb={4}>
        <Typography variant="subtitle1">{title}</Typography>
      </Box>

      {url && (
        <Box width={1000} mt={4}>
          <Input
            label="URL"
            disabled={true}
            required={false}
            value={url}
            InputLabelProps={{
              shrink: true,
              style: {
                color: '#286A6B'
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ paddingRight: 0 }}>
                  <Fade in={copiedSuccessfully} timeout={{ enter: 500, exit: 2000 }}>
                    <small style={{ color: 'green' }}>Copied</small>
                  </Fade>

                  <IconButton
                    onClick={event => {
                      event.preventDefault();
                      copy(String(url)).then(() => {
                        setCopiedSuccessfully(true);
                      });
                    }}>
                    <FileCopyIcon />
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                paddingRight: 0
              }
            }}
          />
        </Box>
      )}
    </Box>
  );
};
